import _export from "../internals/export";
import _arrayForEach from "../internals/array-for-each";
var $ = _export;
var forEach = _arrayForEach;

// `Array.prototype.forEach` method
// https://tc39.es/ecma262/#sec-array.prototype.foreach
// eslint-disable-next-line es/no-array-prototype-foreach -- safe
$({
  target: "Array",
  proto: true,
  forced: [].forEach !== forEach
}, {
  forEach: forEach
});
export default {};